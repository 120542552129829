import { useState } from 'react';
import { useLocation } from 'react-router';
import Store from 'store';

import { getQuotePolicyZipCode, useQuote } from '@pumpkincare/quotes';
import {
  COMPLETE_QUOTE_ID_LS_KEY,
  getMemberRegisterUrl,
  navigateTo,
} from '@pumpkincare/shared';
import { Body1, ButtonStyles, LoaderButton } from '@pumpkincare/shared/ui';
import { postUserVet } from '@pumpkincare/user';
import { VetClinic } from '@pumpkincare/vets/ui';

import { Paths } from '../app-shell';

import styles from './vet-collection.css';

import dogPuppyStethoscope from './dog-puppy-stethoscope.png';

function VetCollection() {
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const token = urlSearchParams.get('token');

  const quoteId = Store.get(COMPLETE_QUOTE_ID_LS_KEY);

  const { data: quoteData } = useQuote({ quoteId });
  const quoteZipCode = getQuotePolicyZipCode(quoteData);

  const [clinic, setClinic] = useState();
  const [isLoading, setIsLoading] = useState(false);

  function handleVetClinicChange(clinic) {
    setClinic(clinic?.existing);
  }

  function handleNextClick() {
    setIsLoading(true);

    postUserVet(clinic.id)
      .then(() => {
        navigateTo(getMemberRegisterUrl(token));
      })
      .catch(() => {
        history.push(Paths.CheckYourEmail);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <h1 className={styles.header}>
          Adding your primary vet info will help us process your claims faster!
        </h1>

        <Body1 isBold className={styles.question}>
          Is there a primary vet we can add to your account?
        </Body1>

        <div className={styles.vetClinicContainer}>
          <VetClinic onChange={handleVetClinicChange} zipcode={quoteZipCode} />

          <LoaderButton
            classes={{ root: styles.nextButton }}
            color={'primary'}
            disabled={!clinic?.id}
            isLoading={isLoading}
            onClick={handleNextClick}
          >
            Next
          </LoaderButton>

          <div className={styles.noVetLink}>
            <a href={getMemberRegisterUrl(token)} className={ButtonStyles.tertiary}>
              I don’t have a primary vet
            </a>
          </div>
        </div>
      </div>

      <div className={styles.bottom}>
        <img alt='' src={dogPuppyStethoscope} />
      </div>
    </div>
  );
}

export default VetCollection;
