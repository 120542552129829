import classNames from 'classnames';
import PropTypes from 'prop-types';

import { formatCurrency } from '@pumpkincare/shared';
import { Body1, Typography } from '@pumpkincare/shared/ui';

import styles from './plan-how-it-works.css';

import stepIconOne from './step-1-icon.png';
import stepIconTwo from './step-2-icon.png';
import stepIconThree from './step-3-icon.png';

function PlanHowItWorks({ topIcon, petName, calculatorData, values, vetName }) {
  const { photoUrl } = calculatorData;
  const { deductible, copay } = values;

  const coverageVetBill = 3500;
  const reimbursementRate = 100 - copay;
  const calculated = coverageVetBill - deductible;
  const cashBack = (calculated / 100) * reimbursementRate;

  return (
    <div className={styles.container}>
      <div className={styles.topIconContainer}>
        <img src={topIcon} className={styles.topIcon} alt='' />
      </div>

      <div className={styles.content}>
        <h2 className={classNames(Typography.h2, styles.sectionTitle)}>
          {`How ${petName}’s Plan Works`}
        </h2>

        <div className={styles.bodySection}>
          <div className={styles.steps}>
            <div className={styles.stepItem}>
              <img src={stepIconOne} alt='' />

              <Body1 className={styles.bodyText}>
                {petName} gets <b>hurt or sick</b> unexpectedly and needs to see a
                veterinarian.
              </Body1>
            </div>

            <div className={styles.stepItem}>
              <img src={stepIconTwo} alt='' />

              <Body1 className={styles.bodyText}>
                {vetName ? (
                  <>
                    Visit <b>{vetName}</b> (or any licensed vet in the US or CAN) and
                    pay as usual.
                  </>
                ) : (
                  <>
                    Visit <b>any licensed vet</b> in the US or CAN for accident or
                    illness care and pay as usual.
                  </>
                )}
              </Body1>
            </div>

            <div className={styles.stepItem}>
              <img src={stepIconThree} alt='' />

              <Body1 className={styles.bodyText}>
                Submit a claim and <b>get paid back</b> for {`${reimbursementRate}%`}
                of all eligible vet bills!
              </Body1>
            </div>
          </div>

          <div className={styles.calculatorSection}>
            <div className={styles.calculator}>
              <div className={styles.calcHeader}>
                <h5 className={styles.calcTitle}>Calculating Your Reimbursement</h5>

                <img src={photoUrl} className={styles.photoUrl} alt='' />
              </div>

              <div className={styles.calcValSection}>
                <div className={styles.calcValItem}>
                  <Body1 className={styles.bodyText}>
                    Take the covered vet bill you paid
                  </Body1>

                  <Body1 className={styles.bodyText}>
                    {formatCurrency(coverageVetBill, { hideZero: true })}
                  </Body1>
                </div>

                <div className={styles.calcValItem}>
                  <Body1 className={styles.bodyText}>
                    Subtract your annual deductible (only paid once a year)
                  </Body1>

                  <Body1 className={styles.bodyText}>
                    {formatCurrency(-deductible, { hideZero: true })}
                  </Body1>
                </div>
              </div>

              <div className={styles.calcValItem}>
                <Body1 className={styles.bodyText}>
                  Get {`${reimbursementRate}%`} of the remaining cost reimbursed:
                </Body1>

                <Body1 className={styles.bodyText}>
                  {formatCurrency(calculated, { hideZero: true })}
                </Body1>
              </div>

              <div className={styles.cashBackSection}>
                <h5>Total Cash Back</h5>

                <h5 className={styles.cashBackValue}>
                  {formatCurrency(cashBack, { hideZero: true })}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

PlanHowItWorks.propTypes = {
  topIcon: PropTypes.string.isRequired,
  petName: PropTypes.string.isRequired,
  calculatorData: PropTypes.shape({
    photoUrl: PropTypes.string,
  }).isRequired,
  values: PropTypes.shape({
    deductible: PropTypes.string,
    copay: PropTypes.string,
  }).isRequired,
};

PlanHowItWorks.defaultProps = {
  topIcon: '',
  petName: '',
  calculatorData: { photoUrl: '' },
  values: { deductible: 0, copay: 0 },
  vetName: '',
};

export default PlanHowItWorks;
