import { useLocation } from 'react-router-dom';

import { useQuote } from '@pumpkincare/quotes';
import { getIsLoggedIn } from '@pumpkincare/shared';

import { Paths } from '../../app-shell';

export default function useQuoteNextStep() {
  const { pathname } = useLocation();
  const { data: quoteData } = useQuote();

  let nextPathname;

  switch (pathname) {
    case Paths.Intro:
      nextPathname = Paths.PetInfo;
      break;

    case Paths.PetInfo:
      nextPathname =
        quoteData.pets.length > 1 ? Paths.PlanSelection : Paths.PetQuestions;
      break;

    case Paths.PetQuestions:
      if (quoteData.isRegistered || getIsLoggedIn()) {
        nextPathname = Paths.PlanSelection;
      } else {
        nextPathname = Paths.Register;
      }
      break;

    case Paths.Register:
      nextPathname = Paths.PlanSelection;
      break;

    case Paths.EmbarkHello:
      nextPathname = Paths.Intro;
      break;

    default:
      nextPathname = Paths.Intro;
      break;
  }

  return nextPathname;
}
