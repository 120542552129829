import { createRef, memo, useEffect } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

function SslTrustIcons() {
  const digicertSeal = createRef();
  const { punks1777DisplayDigicertBadge } = useFlags();

  useEffect(() => {
    if (punks1777DisplayDigicertBadge) {
      window.__dcid = window.__dcid || [];

      window.__dcid.push({ cid: 'DigiCertClickID_uuafwuYp', tag: 'uuafwuYp' });

      (function () {
        const cid = window.document.createElement('script');
        cid.async = true;
        cid.src = '//seal.digicert.com/seals/cascade/seal.min.js';

        const s = window.document.getElementsByTagName('script');
        const ls = s[s.length - 1];

        ls.parentNode.insertBefore(cid, ls.nextSibling);
      })();
    }
  }, [punks1777DisplayDigicertBadge]);
  return punks1777DisplayDigicertBadge ? (
    <div
      id='DigiCertClickID_uuafwuYp'
      ref={digicertSeal}
      data-testid='digicert'
    ></div>
  ) : null;
}

export default memo(SslTrustIcons);
